import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import API from "../../../api/api";

async function createAppointment(reason, appointment_time, patient_id, user_id, institution_id, other_details, status) {
    try {
        const data = {
            reason,
            patient: patient_id,
            institution: institution_id,
            staff: user_id,
            appointment_time,
            other_details,
            status,
        };

        const response = await API.post("createappointment/", data);
        if (response.status === 200) {
            alert("Appointment created successfully");
        }
    } catch (error) {
        console.error("Error creating appointment:", error);
        alert("Failed to create the appointment. Please try again.");
    }
}

function FollowUp({patient_fname, details, writeDetails, value, patient, institution, writeDate, reviewMode}) {
    const [reason, setReason] = React.useState("");

    return (
        <Accordion style={{borderRadius: 15, minWidth: 500}}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>Schedule follow-up review</Typography>
            </AccordionSummary>
            <hr/>
            <AccordionDetails>
                <Box>
                    <TextField
                        id="Reason"
                        label="Reason"
                        variant="outlined"
                        multiline
                        style={{minWidth: 470, paddingBottom: 15}}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                    <br/>
                    <TextField
                        id="details"
                        label="Other details"
                        variant="outlined"
                        multiline
                        style={{minWidth: 470, paddingBottom: 15}}
                        value={details}
                        onChange={writeDetails}
                    />
                    <Typography variant="h6" marginBottom={1}>
                        Please select the Date and time to see {patient_fname}
                    </Typography>
                    <Stack spacing={3}>
                        <TextField
                            label="Next appointment"
                            type="datetime-local"
                            sx={{width: 250}}
                            InputLabelProps={{shrink: true}}
                            value={value}
                            onChange={writeDate}
                        />
                    </Stack>

                    <Button
                        variant="outlined"
                        disabled={reviewMode}
                        onClick={async () => {
                            await createAppointment(
                                reason,
                                value,
                                patient,
                                localStorage.getItem('user_id'),
                                institution,
                                details,
                                "not_yet_seen"
                            );
                        }}
                    >
                        Create Appointment
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default FollowUp;
